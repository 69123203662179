<template>
	<div class="formulaire__bug">
		<svg class="logo" fill="#f44039" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 24 30" enable-background="new 0 0 24 24" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" d="M12,23.917  C5.419,23.917,0.084,18.582,0.084,12C0.084,5.419,5.419,0.083,12,0.083c6.582,0,11.917,5.336,11.917,11.917  C23.916,18.582,18.581,23.917,12,23.917z M12,2.834c-5.062,0-9.166,4.104-9.166,9.166c0,5.063,4.104,9.167,9.166,9.167  c5.063,0,9.167-4.104,9.167-9.167C21.167,6.938,17.063,2.834,12,2.834z M16.158,15.241l-0.928,0.929c-0.17,0.17-0.448,0.17-0.618,0  l-2.632-2.631l-2.63,2.631c-0.171,0.17-0.448,0.17-0.619,0l-0.929-0.929c-0.17-0.171-0.17-0.448,0-0.619l2.632-2.63L7.801,9.361  c-0.17-0.171-0.17-0.448,0-0.619L8.73,7.813c0.171-0.171,0.447-0.171,0.619,0l2.63,2.63l2.632-2.63c0.17-0.171,0.448-0.171,0.618,0  l0.928,0.929c0.171,0.171,0.171,0.448,0,0.619l-2.631,2.631l2.631,2.63C16.329,14.793,16.329,15.07,16.158,15.241z"/></svg>
		<h3 class="mt-10">Bug technique</h3>
		<v-form 
			v-model="opt_form.valide"
			mode="aggressive"
			ref="form"
		>
			<InputSearchCab 
				@change="cab_change"
				class="mt-8"
				require
			>
			</InputSearchCab>
			<v-text-field 
				outlined
				v-model="cab"
				:rules="opt_form.rules.cab"
				class="mt-4"
				v-show="false"
				id="hidden-searchcab"
			></v-text-field>
			<v-select
				outlined
				:items="opt_form.liste_type"
				v-model="type"
				label="Type de probléme"
				item-text="label"
				item-value="code"
				:rules="opt_form.rules.type"
			></v-select>
			<v-textarea
				outlined
				name="input-7-4"
				label="Rédigez votre demande"
				v-model="content"
				:rules="opt_form.rules.content"
				class=""
			></v-textarea>
			<InputFileUpload
				colorBack="#3d2f59"
				class="mt-4 mb-10"
				@change="files_changes"
			></InputFileUpload>
			<v-btn 
				class="mt-0" 
				depressed 
				color="#f44039" 
				:dark="opt_form.valide"
				:disabled="!opt_form.valide"
				@click="send"
			>
				Envoyer
			</v-btn>
			<v-btn 
				class="ml-4" 
				depressed 
				@click="$router.go(-1);"
			>
				Retour
			</v-btn>		
		</v-form>
	</div>
</template>

<script>
	import InputSearchCab from '../InputCabSearch.vue'
	import InputFileUpload from '../InputFileUpload.vue'
	import { mapActions, mapMutations } from 'vuex';

	export default {
		name: 'Bug',
		components : {
			InputSearchCab,
			InputFileUpload
		},
		data: () => ({
			cab: "",
			type: "",
			content: "",
			files: [],
			opt_form: {
				liste_type : [],
				cab_error : false,
				lazy: false,
				valide: false,
				rules : {
					content : [
						v => v != "" ? true : 'Le message est requis',
						v => v.length > 5 ? true : "Le message est trop court"
					],
					type : [
						v => v != "" ? true : "Le type est requis"
					],
					cab : [
						v => v != "" ? true : "Le cab est requis",
					]
				}
			},
		}),
		created: function() {
			var _this = this;
			this.getBugListType()
			.then(resultat => {
				_this.opt_form.liste_type = resultat;
			})
		},
		methods: {
			...mapActions('formulaires', [
				"sendBug",
				"getBugListType"
			]),
			...mapMutations('global', [
				"sendSuccessNotif"
			]),
			cab_change: function(e) {
				this.cab = e;
			},
			files_changes: function(e) {
				this.files = e;
			},
			send: function() {
				var _this = this;

				this.sendBug({
					cab: this.cab,
					type: this.type,
					content: this.content,
					files: this.files.map(x => {
						return x.file_id
					}),
				})
				.then(() => {
					_this.sendSuccessNotif("Opération effectuée")
					_this.$router.push('/');
				})
			}
		},
	};
</script>

<style lang="scss">
	.formulaire__bug {
		width: 40%;
		border: 4px solid #f1f1f1;
		border-radius: 16px;
		margin-top: 30px;
		padding: 20px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: display;
		padding-bottom: 30px;

		.logo
		{
			width: 50px;
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			top: -25px;
			left:0;
			right: 0;
			z-index: 1000;
		}
	}

	@media only screen and (min-width: 701px) and (max-width: 800px) {
		.formulaire__bug
		{
			width: 50%;
		}
	}

	@media only screen and (min-width: 420px) and (max-width: 700px) {
		.formulaire__bug
		{
			width: 80%;
		}
	}

	@media only screen and (min-width: 0px) and (max-width: 419px) {
		.formulaire__bug
		{
			width: 90%;
		}
	}
</style>